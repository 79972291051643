.admin-panel-title {
  background-color: #00a1df;
  color: #fff;
  padding-left: 30px;
  border-top: 0px;
  border-bottom: 0px;
  width: auto;
}

#adminpanel {
  /*
  position: absolute;
  top: 500;
  right: 0;
  bottom: 0;
  width: 400px;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 0;
  margin: 0;
*/

  label {
    width: 200px;
    display: inline-block;
  }

  input:not([type="checkbox"]) {
    width: 80%;
  }

  .adminpanel__contents {
  }

  .subTitle {
    background-color: #00a1df;
    color: #fff;
    padding-left: 30px;
    border-top: 0px;
    border-bottom: 0px;
    width: auto;
  }

  button,
  input[type="button"] {
    /*
      background-color: #00a1df;
      color: #fff;
  */
    border-color: #007cac;
    font-weight: normal;
    text-align: center;
    cursor: pointer;
    border: 1px solid;
    white-space: nowrap;
    padding: 4px 12px;
    line-height: 1.5;
    border-radius: 3px;
  }
}
