.saved {
  background-color: white;

  .show-if-not-saved {
    display: none;
  }
}
.notsaved {
  background-color: lightpink;
}

.show-if-not-saved {
  padding: 1.25rem;
  background: white;
  margin-top: 1.25rem;
}
